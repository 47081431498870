import Cookies from 'js-cookie';

const portalCookie = Cookies.withAttributes({
	path:   import.meta.env.VITE_COOKIE_BASE_PATH,
	domain: import.meta.env.VITE_COOKIE_BASE_DOMAIN,
	secure: import.meta.env.VITE_SECURE_COOKIE === 'TRUE',
	expires: 365,
});


export default portalCookie;
