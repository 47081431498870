import { Card, Col, Row, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { actions } from '@/core/store';
import { actions as invoiceActions } from '@/pages/Invoices/Invoices.redux.js';
import Cookies from '@/portal-cookie';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const Logout = () => {

	const dispatch = useDispatch();
	const setAuth = useCallback((data) => {
		dispatch(actions.auth.setState(data));
	}, [ dispatch ]);

	useEffect(() => {
		Cookies.remove('authToken');
		Cookies.remove('TS_UserKey');
		Cookies.remove('TS_User_type');
		setAuth({loggedIn: false, pending: false});
		dispatch(invoiceActions.reset());
	}, [ setAuth ]);

	return (
		<Row className="ts_home_content_container logout">
			<Card title={<div><LogoutOutlined /> Log Out</div>} className="ts_content_container ts_portal_card">
				<Typography>
					You have been successfully logged out.  Please click <Link className="ts_logout_button" to="/">here</Link> if you would
					like to log in again.
				</Typography>
			</Card>
		</Row>

	);
};


export default Logout;
