import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import auth, { actions as authActions } from '@/core/auth.redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import layout, { actions as layoutActions } from '@/core/Layout/Layout.redux';
import notifications, { actions as notificationActions } from '@/core/Notifications/Notifications.redux';
import permissions, { actions as permissionsActions } from '@/core/permissions.redux';
import userDetail, { actions as userDetailActions } from '@/core/UserDetails/UserDetails.redux';
import userManagement, { actions as userManagementActions } from '@/core/UserManagement/UserManagement.redux';

import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';

export const history = createBrowserHistory({
	basename: import.meta.env.VITE_BASE_URL,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
						 ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
						 : compose;


let reducerTypes = {
	auth,
	layout,
	notifications:  notifications,
	permissions,
	router:         connectRouter(history),
	userManagement: userManagement,
	userDetail: userDetail,
};


export const actions = {
	auth:           authActions,
	layout:         layoutActions,
	notifications:  notificationActions,
	permissions:    permissionsActions,
	userManagement: userManagementActions,
	userDetail: userDetailActions
};


export const createRootReducer = (history) => combineReducers(reducerTypes);

const store = createStore(
	createRootReducer(history),
	composeEnhancers(
		applyMiddleware(
			routerMiddleware(history),
			thunkMiddleware,
		),
	),
);


export function registerReduxStore(key, pluginReducers, pluginActions) {
	if(pluginReducers) {
		reducerTypes[key] = pluginReducers;

		let newReducers = Object.keys(reducerTypes)
			.sort()
			.reduce((accumulator, key) => {
				accumulator[key] = reducerTypes[key];
				return accumulator;
			}, {});

		store.replaceReducer(combineReducers(newReducers));
	}

	if(pluginActions) {
		actions[key] = pluginActions;
	}
}






export default store;
