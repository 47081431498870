import api from '@/api';
import Cookies from '@/portal-cookie';
import base64toPDF from '../../lib/base64ToPDF';

let types = {
	SET_STATE: 'SET_STATE',
	SET_REORDER_NOTES: 'SET_REORDER_NOTES',
	SET_REORDER_LINES: 'SET_REORDER_LINES',
	SET_REORDER_LINE_QTY: 'SET_REORDER_LINE_QTY',
	RESET_REORDER_FORM: 'RESET_REORDER_FORM'
};



const initialState = {
	salesOrders: [],
	header: {},
	reOrderForm: {
		lines: [],
		notes: '',
	},
	cardCode: '',
	cardType: '',
	cardName: '',
	loading: false,
	loaded: false,
	submitPending: false,
	loadedVendorCode: false,
	customerProfileId_from_SAP: null,
	attachmentPreviewURL: '',
	showAttachmentModal: false,
	showReorderForm: false,
	showSOPDFDialog: false,
	pdfModalLoading: false,
	showPreviewPDFDialog: false,
	pdfModalKey: null,
	pdfFilePreview: null,
	pdfModalData: null,
}


export const reducers = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_STATE:
		{
			return {...state, ...action.data };
		}
		case types.SET_REORDER_NOTES:
		{
			return {
				...state,
				reOrderForm: {
					...state.reOrderForm,
					lines: [...state.reOrderForm.lines],
					notes: action.data.notes,
				}
			}
		}
		case types.SET_REORDER_LINES:
			{
				return {
					...state,
					reOrderForm: {
						...state.reOrderForm,
						lines: action.data.lines,
					}
				}
			}
		case types.SET_REORDER_LINE_QTY:
			return {
				...state,
				reOrderForm: {
					...state.reOrderForm,
					lines: state.reOrderForm.lines.map((line, index) =>
						index === action.index
							? { ...line, rdr1_quantity: action.value }
							: line
					),
				},
			}
			case types.RESET_REORDER_FORM:
				{
					return {
						...state,
						reOrderForm: {
							...state.reOrderForm,
							...action.data,
						},
					};
				}
		default:
			return state;

};
}
export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
	setReorderNotes: (data) => ({ type: types.SET_REORDER_NOTES, data }),
	setReorderLines: (data) => ({ type: types.SET_REORDER_LINES, data }),
	setReorderLineQTY: (index, value) => ({ type: types.SET_REORDER_LINE_QTY, index, value }),
	resetReOrderForm: (data) => ({ type: types.RESET_REORDER_FORM, data }),
	loadSalesOrders: () => async (dispatch, getState) => {
		console.log('[SalesOrders.redux] loadSalesOrders');

		const 
			state    = getState(),
			loading  = state.salesOrders.loading,
			cardCode = state.salesOrders.cardCode,
			cardType = state.salesOrders.cardType;

		
		//console.log('cardCode', cardCode);
		//console.log('cardType', cardType);

		if (loading) {
			return false;
		}

		dispatch(actions.setState({ loading: true, loaded: false }));

		try {
			let response = await api.get('/sales-orders', {
				params: {
					cardCode
				},
			});
			console.log('res', response)

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					salesOrders: response,
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					salesOrders: [],
					cardName: '',
				},
			});
		}
	},

	//loadAttachments
	getAttachments: (atcEntry) => async (dispatch) => {
		try {

			const atcData = await api.get('/sales-orders/attachments', {
				params: {
					atcEntry: atcEntry,
				},
			});
			console.log('loadAttachments response', atcData)
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					picturesLoading: false,
					modalOpen: true,
					pictureAttachments: atcData.attachments,
					msgAttachments: atcData.msgAttachments, 	// base64
					msgObjects: atcData.msgObjects, 			// text information in objects
					attachmentErrors: atcData.errors
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode: true,
					cardCode: '',
					cardType: '',
				},
			});
		}
	},

	//Gets data from sales order
	loadReorder: (docEntry) => async (dispatch) => {
		// just clear notes, lines will be overwritten. If I try to reset the lines too its causing issues. this works for now.
		dispatch(actions.resetReOrderForm({notes: ''}))
		try {

			const header = await api.get('/sales-orders/reorder/header', {
				params: {
					docEntry: docEntry,
				},
			});

			const lines = await api.get('/sales-orders/reorder/lines', {
				params: {
					docEntry: docEntry,
				},
			});

			let inventoryItems = lines.filter(line => line.oitm_invntItem !== 'N');

			//console.log('loadReorder response header', header[0])
			//console.log('loadReorder response lines', lines)

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					showReorderForm: true,
					header: header[0],
				},
			});
			dispatch({
				type: types.SET_REORDER_LINES,
				data: {
					lines: inventoryItems,
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false
				},
			});
		}
	},

	submitReorder: () => async (dispatch, getState) => {

		dispatch(actions.setState({submitPending: true}))
		try {
			const userKey = Cookies.get('TS_UserKey', '');
			console.log('submitReorder userKey', userKey)
			const state     = getState(),
            	lines       = state.salesOrders.reOrderForm.lines,
				notes       = state.salesOrders.reOrderForm.notes,
            	header      = state.salesOrders.header,
				cardCode	= state.salesOrders.cardCode,
				cardType	= state.salesOrders.cardType,
				cardName	= state.salesOrders.header.ocrd_cardName,
				orginalSO	= state.salesOrders.header.ordr_project_name,
				projectName	= state.salesOrders.header.ordr_docNum;

			const result = await api.post('/sales-orders/reorder/send-reorder', {
					lines: lines,
					notes: notes,
					header: header,
					cardCode: cardCode,
					cardType: cardType,
					cardName: cardName,
					userKey: userKey,
					orginalSO: orginalSO,
					projectName: projectName
				},
			);

			//console.log('Result', result)

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					showReorderForm: false,
					submitPending: false,
				},
			});

		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					submitPending: false,
				},
			});
		}
	},


	loadVendorCode: () => async (dispatch) => {
		try {
			const userKey = Cookies.get('TS_UserKey', '');
			const response = await api.get('/invoices/vendor-code', {
				params: {
					userKey: userKey,
				},
			});
			console.log('loadVendorCode userKey', userKey)
			console.log('loadVendorCode response', response)
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode: true,
					cardCode: response.BPCode,
					cardType: response.BPType,
					customerProfileId_from_SAP: response.CustomerProfileId,
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode: true,
					cardCode: '',
					cardType: '',
				},
			});
		}
	},

	load_pdf_document: (docEntry) => async() => {
		const response = await api.get('/sales-orders/order-document', {
			params: {
				docEntry: docEntry,
			},
		});

		let fileData = response.document.FileData;
		fileData = base64toPDF(fileData);

		const fileURL = URL.createObjectURL(fileData);

		if (fileData) {
			window.open(fileURL);
		} else {
			message.error(`Could not load report for document ${docEntry}`);
		}
	},

	resetSOPDFDialog: () => ({
		type: types.SET_STATE, data: {
			showSOPDFDialog: false,
		},
	}),

	loadInvoicePreview: (docEntry) => async (dispatch) => {
		console.log('invoice pdf', docEntry)

		const objType = '13'; //portal_ar_invoice
		try {
			dispatch(actions.setState({ pdfModalLoading: true }));

			const response = await api.get('/invoices/preview', {
				params: {
					docEntry,
					objType
				}
			});

			let fileData = response.FileData;
			let pdf = base64toPDF(fileData);

			
			dispatch(actions.setState({ pdfModalKey: docEntry }));
			dispatch(actions.setState({ pdfModalData: fileData }));
			dispatch(actions.setState({ pdfFilePreview: pdf }));
			dispatch(actions.setState({ pdfModalLoading: false }));
			
			//const fileURL = URL.createObjectURL(fileData);
			// if (fileData) {
			// 	window.open(fileURL);
			// } else {
			// 	message.error(`Could not load report for document ${docEntry}`);
			// }
		} catch (e) {
			console.error(e);
			message.error('Could not load invoice preview');
			dispatch(actions.setState({ pdfModalLoading: false }));
		}
	},

	loadSalesOrderPreview: (docEntry, objType) => async (dispatch) => { 
		
		console.log('[invoices.redux] loadSalesOrderPreview  docEntry: ' + docEntry);
		try {
			dispatch(actions.setState({ pdfModalLoading: true }));
			const response = await api.get('sales-orders/preview', {
				params: {
					docEntry,
					objType: '17'
				}
			});

			console.log(response)
			let fileData = response.FileData;
			let pdf = base64toPDF(fileData);

			dispatch(actions.setState({ pdfModalKey: docEntry }));
			dispatch(actions.setState({ pdfModalData: fileData }));
			dispatch(actions.setState({ pdfFilePreview: pdf }));
			dispatch(actions.setState({ pdfModalLoading: false }));
		} catch (e) {
			console.error(e);
			message.error('Could not load sales order preview');
			dispatch(actions.setState({ pdfModalLoading: false }));
		}
	},

};
