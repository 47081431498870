import InvoiceState, { actions } from '@/pages/Invoices/Invoices.redux.js';
import { ProfileOutlined, WalletOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';

import { registerNavigation } from '@/core/navigation';
import { registerReduxStore } from '@/core/store';
import { registerRoutes } from '@/core/routes';

const Invoices = lazy(() => import('./Invoices'));



registerReduxStore('invoices', InvoiceState, actions);


registerRoutes([
	{
		path:        '/invoices',
		exact:       true,
		render:      <Invoices />,
		title:       'Pay Invoice',
		private:     true,
		permissions: [],
	},
]);



registerNavigation({
	title:       'Invoices',
	permissions: [],
	single:      false,
	top:         true,
	home:        true,
	icon:        <WalletOutlined />,
	children:    [
		{
			title:       'Invoices List',
			url:         '/invoices',
			icon:        <ProfileOutlined />,
			permissions: [],
		},
	],
});


console.log('Registered Invoices Plugin');
