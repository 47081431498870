import React, { useState } from 'react';
import { Button, Divider, Form, Input, Modal, Typography  } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@/core/store';
const { Text } = Typography;


const PasswordResetForm = ({ open, handleClose }) => {
	const [ pending, setPending ] = useState(false),
		  [ form ]                = Form.useForm(),
          dispatch                = useDispatch(),
          expiredCode             = useSelector(state => state.auth.expiredCode),
          resetPasswordModal      = useSelector(state => state.auth.loadResetPasswordModal);


	const handleSubmitEmail = async (values) => {
		setPending(true);

		try {
            dispatch(actions.auth.submitEmail(values.email));
			setPending(false);
		} catch(err) {
			setPending(false);
			console.error(err);
		}
	};

    const handleSubmitResetForm = async (values) => {
		setPending(true);

		try {
            dispatch(actions.auth.resetPassword(values.email, values.password, values.code));
			setPending(false);
		} catch(err) {
			setPending(false);
			console.error(err);
		}
	};



	const closeModal = () => {
		if(pending) {
			return false;
		}
        dispatch(actions.auth.setState({loadSubmitEmailModal: false, loadResetPasswordModal: false}));
		form.resetFields();
		handleClose();
	};



	return (
        <>
            <Modal
                title={'Request to reset password'}
                visible={open}
                centered
                onCancel={closeModal}
                maskClosable={false}
                footer={null}
            >
                <Text>A code will be sent to your email to reset your password.</Text>
                <Form
                    className="ts_form_margin"
                    name="submit_email"
                    form={form}
                    onFinish={handleSubmitEmail}
                >
                    <Form.Item
                        name="email"
                        rules={[ { required: true, message: 'Email is required' } ]}
                    >
                        <Input addonBefore="Email" className="ts_padding" disabled={pending} />
                    </Form.Item>

                    <Divider />

                    <Button
                        type="primary"
                        htmlType="submit"
                        className="ts_margin_left"
                        disabled={pending}
                    >
                        Submit
                    </Button>

                    <Button
                        onClick={closeModal}
                        className="ts_margin_left"
                        disabled={pending}
                    >
                        Cancel
                    </Button>

                </Form>
            </Modal>
            { resetPasswordModal && 
                <Modal
                    title={'Reset Password'}
                    visible={resetPasswordModal}
                    centered
                    onCancel={closeModal}
                    maskClosable={false}
                    footer={null}
                >
                    <Text>Please check your email for the code.</Text>
                    <Form
                        className="ts_form_margin"
                        name="submit_email"
                        form={form}
                        onFinish={handleSubmitResetForm}
                    >
                        <Form.Item
                            name="email"
                            rules={[ { required: true, message: 'Email is required' } ]}
                        >
                            <Input addonBefore="Email" className="ts_padding" disabled={true} />
                        </Form.Item>

                        <Form.Item
                            name="code"
                            rules={[ { required: true, message: 'Code is required' } ]}
                        >
                            <Input addonBefore="Code" className="ts_padding" disabled={pending} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Password is required' },
                                {
                                    min: 10,
                                    message: 'Password must be at least 10 characters long.',
                                },
                                {
                                    pattern:  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).*$/,
                                    message: 'Password must include at least 1 uppercase letter, 1 number, and 1 special character.',
                                },
                            ]}
                        >
                            <Input.Password addonBefore="New Password" className="ts_padding" disabled={pending} />
                        </Form.Item>
                        <Form.Item
                            name="confirm_password"
                            dependencies={['password']}
                            rules={[
                                { required: true, message: 'Confirm Password is required' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Passwords do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password addonBefore="Confirm Password" className="ts_padding" disabled={pending} />
                        </Form.Item>

                        <Divider />

                        <Button
                            type="primary"
                            htmlType="submit"
                            className="ts_margin_left"
                            loading={pending}
                            disabled={expiredCode}
                        >
                            Submit
                        </Button>

                        <Button
                            onClick={closeModal}
                            className="ts_margin_left"
                            disabled={pending}
                        >
                            Cancel
                        </Button>

                    </Form>
                </Modal>
            }
        </>
	);
};



export default PasswordResetForm;
