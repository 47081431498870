import SalesOrdersState, { actions } from '@/pages/SalesOrders/SalesOrders.redux.js';
import { ProfileOutlined, DollarOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';

import { registerNavigation } from '@/core/navigation';
import { registerReduxStore } from '@/core/store';
import { registerRoutes } from '@/core/routes';

const SalesOrders = lazy(() => import('./SalesOrders'));



registerReduxStore('salesOrders', SalesOrdersState, actions);


registerRoutes([
	{
		path:        '/sales-orders',
		exact:       true,
		render:      <SalesOrders />,
		title:       'Sales Orders',
		private:     true,
		permissions: [],
	},
]);



registerNavigation({
	title:       'Sales Orders',
	permissions: [],
	single:      false,
	top:         true,
	home:        true,
	icon:        <DollarOutlined />,
	children:    [
		{
			title:       'View Sales Orders',
			url:         '/sales-orders',
			icon:        <ProfileOutlined />,
			permissions: [],
		},
	],
});


console.log('Registered Invoices Plugin');